<template>
  <Toast />
  <Dialog
    v-model:visible="activarModal"
    :style="{ width: '900px' }"
    header="DETALLE PRODUCTOS"
    :modal="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <span><strong>PRODUCTO/SERVICIO:</strong></span>
          <Dropdown
            v-model="data_producto.tipo_catalogo"
            :options="tipos_catalogo"
            optionLabel="label"
            optionValue="value"
            @change="changeTipoCatalogo"
            :disabled="
              data_producto.id !== null && data_producto.id !== undefined
            "
          />
        </div>
        <div class="field col-12 md:col-2">
          <span
            ><strong>C&Oacute;DIGO INTERNO:</strong>
            <span class="p-invalid" v-if="facturar_producto == 1">*</span></span
          >
          <InputText
            placeholder="Código Interno"
            v-model="data_producto.codigo_interno"
          ></InputText>
          <small class="p-invalid" v-if="errors.codigo_interno">{{
            errors.codigo_interno[0]
          }}</small>
        </div>
        <div
          class="field col-12 md:col-2"
          v-if="data_producto.tipo_catalogo == 1"
        >
          <span><strong>C&Oacute;DIGO ORIGEN: </strong> </span>
          <Textarea
            placeholder="Código Origen"
            v-model="data_producto.codigo_origen"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.codigo_origen">{{
            errors.codigo_origen[0]
          }}</small>
        </div>
        <div
          :class="
            data_producto.tipo_catalogo == 1
              ? 'field col-12 md:col-4'
              : 'field col-12 md:col-5'
          "
        >
          <span
            ><strong>NOMBRE DE PRODUCTO | DESCRIPCI&Oacute;N: </strong>
            <span class="p-invalid">*</span></span
          >
          <Textarea
            placeholder="Descripción/Nombre del Producto"
            autofocus
            v-model="data_producto.descripcion"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.descripcion">{{
            errors.descripcion[0]
          }}</small>
        </div>
        <div
          class="field col-12 md:col-2"
          v-if="data_producto.tipo_catalogo == 1"
        >
          <span><strong>MEDIDAS: </strong></span>
          <InputText placeholder="Medidas" v-model="data_producto.medidas" />
          <small class="p-invalid" v-if="errors.medidas">{{
            errors.medidas[0]
          }}</small>
        </div>
        <div
          class="field col-12 md:col-3"
          v-if="
            data_producto.tipo_catalogo == 1 &&
            'Grupo Crear' in auth.user.permissions
          "
        >
          <span><strong>GRUPO: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="grupoSelect"
              :options="grupos"
              optionLabel="nombre"
              :filter="true"
              placeholder="Seleccione un grupo"
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.nombre }} </span>
                </div>
              </template>
            </Dropdown>
            <!-- v-if="'Grupo Crear' in auth.user.permissions" -->
            <Button
              class="p-button-info"
              icon="pi pi-plus"
              @click="activarGrupoModal"
            />
          </div>
        </div>
        <div
          v-show="false"
          class="field col-12 md:col-2"
          v-if="
            data_producto.tipo_catalogo == 1 &&
            'SubGrupo Crear' in auth.user.permissions
          "
        >
          <span><strong>SUB-GRUPO: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="subgrupoSelect"
              :options="subgrupos"
              optionLabel="nombre"
              :filter="true"
              placeholder="Seleccione..."
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.nombre }} </span>
                </div>
              </template>
            </Dropdown>
            <Button
              class="p-button"
              icon="pi pi-plus"
              @click="activarSubGrupoModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.subgrupo_id">{{
            errors.subgrupo_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span
            ><strong>MARCA: </strong> <span class="p-invalid">* </span></span
          >
          <span
            class="ml-1"
            v-if="
              data_producto.fabricas != null && data_producto.tipo_catalogo == 1
            "
            v-tooltip.top="'PROVEEDOR'"
          >
            {{ data_producto.fabricas.nombre_proveedor }}
          </span>

          <div class="p-inputgroup">
            <Dropdown
              v-model="laboratorioSelect"
              :options="laboratorios"
              optionLabel="nombre"
              :filter="true"
              placeholder="Seleccione un una Marca"
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span v-if="data_producto.tipo_catalogo == 1"
                    >{{ slotProps.option.nombre }} -
                    {{ slotProps.option.nombre_proveedor }}
                  </span>
                  <span v-else>{{ slotProps.option.nombre }}</span>
                </div>
              </template>
            </Dropdown>
            <Button
              class="p-button"
              v-if="'Laboratorio Crear' in auth.user.permissions"
              icon="pi pi-plus"
              @click="activarLaboratorioModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.fabrica_id">{{
            errors.fabrica_id[0]
          }}</small>
        </div>
        <div
          class="field col-12 md:col-3"
          v-if="data_producto.tipo_catalogo == 1"
        >
          <span><strong>PROCEDENCIA: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="procedenciaSelect"
              :options="procedencias"
              optionLabel="nombre"
              :filter="true"
              placeholder="Selecione..."
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.nombre }} </span>
                </div>
              </template>
            </Dropdown>
            <Button
              class="p-button"
              icon="pi pi-plus"
              @click="activarProcedenciaModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.procedencia">{{
            errors.procedencia[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span
            ><strong>U.MEDIDA: </strong> <span class="p-invalid">*</span></span
          >
          <div class="p-inputgroup">
            <Dropdown
              v-model="unidadSelect"
              :options="unidadesmedidas"
              :filter="true"
              optionLabel="nombre"
              placeholder="Seleccione una unidade medida"
            >
              <template #option="slotProps">
                <span> {{ slotProps.option.nombre }} </span>
              </template>
            </Dropdown>
            <Button
              class="p-button"
              v-if="'Unidad de Medida Crear' in auth.user.permissions"
              icon="pi pi-plus"
              @click="activarUnidadMedidaModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.unidadmedida_id">{{
            errors.unidadmedida_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>SUCURSAL: </strong></span>
          <Dropdown
            v-model="sucursalSelect"
            :options="sucursales"
            optionLabel="nombre"
            @change="changeSucursal"
          />
        </div>
        <div
          class="field col-12 md:col-2"
          v-if="data_producto.tipo_catalogo == 1 && stock_decimales"
        >
          <span><strong>STOCK ACTUAL: </strong></span>
          <InputNumber
            v-model="data_producto.stock_actual"
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            mode="decimal"
            locale="de-ED"
            :disabled="!actualizar_precio_compra"
          />
        </div>
        <div
          class="field col-12 md:col-2"
          v-if="data_producto.tipo_catalogo == 1 && !stock_decimales"
        >
          <span><strong>STOCK ACTUAL: </strong></span>
          <InputNumber
            v-model="data_producto.stock_actual"
            :inputClass="'text-right'"
            :min="0"
            locale="de-ED"
            :disabled="!actualizar_precio_compra"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span v-if="data_producto.tipo_catalogo == 1"
            ><strong>P.U COMPRA: </strong></span
          >
          <span v-else><strong>PRECIO SERVICIO: </strong></span>

          <div class="p-inputgroup">
            <InputNumber
              v-model="data_producto.precio_compra"
              :inputClass="'text-right'"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              locale="de-ED"
              :disabled="!actualizar_precio_compra"
              v-tooltip.top="'Precio Unitario de Compra'"
            />
            <Button
              :disabled="!('Producto Actualizar' in auth.user.permissions)"
              class="p-button-info"
              icon="pi pi-pencil"
              @click="activarActualizarPrecioCompra"
              v-tooltip.top="
                'Actualizar Precio de Compra en productos existentes'
              "
            />
          </div>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>PRECIO UNIT. VENTA: </strong></span>
          <div class="p-inputgroup">
            <InputNumber
              v-model="data_producto.precio_sugerido"
              :inputClass="'text-right'"
              :min="0"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              locale="de-ED"
              :disabled="data_producto.id && !actualizar_precio_venta"
            />
            <Button
              v-if="'Producto Actualizar' in auth.user.permissions"
              :disabled="!data_producto.id"
              class="p-button-info"
              icon="pi pi-pencil"
              @click="activarActualizarPrecioVenta"
              v-tooltip.top="
                'Actualizar Precio de Venta en productos existentes'
              "
            />
          </div>
          <small class="p-invalid" v-if="errors.precio_sugerido">{{
            errors.precio_sugerido[0]
          }}</small>
        </div>
        <div
          class="field col-12 md:col-3"
          v-if="data_producto.tipo_catalogo == 1"
        >
          <span><strong>STOCK M&Iacute;NIMO: </strong></span>
          <InputNumber
            :inputClass="'text-right'"
            v-model="data_producto.stock_minimo"
            :min="0"
          />
          <small class="p-invalid" v-if="errors.stock_minimo">{{
            errors.stock_minimo[0]
          }}</small>
        </div>
        <div
          class="field col-12 md:col-2"
          v-if="data_producto.tipo_catalogo == 1 && mostrar_cif"
        >
          <span><strong>PRECIO FOB: </strong></span>
          <InputNumber
            :inputClass="'text-right'"
            v-model="data_producto.precio_cif"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
          />
        </div>
        <div
          v-show="false"
          class="field col-12 md:col-2"
          v-if="data_producto.tipo_catalogo == 1"
        >
          <span><strong>SOTCK M&Aacute;XIMO: </strong></span>
          <InputNumber
            v-model="data_producto.stock_maximo"
            showButtons
            placeholder="0"
            :inputClass="'text-right'"
            :min="0"
            locale="de-ED"
          />
          <small class="p-invalid" v-if="errors.stock_maximo">{{
            errors.stock_maximo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3" v-show="false">
          <span><strong>PU VENTA 2:</strong></span>
          <InputNumber
            id="pv2"
            placeholder="0.00"
            v-model="data_producto.pv2"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            :disabled="data_producto.id && !actualizar_precio_venta"
          />
          <small class="p-invalid" v-if="errors.pv2">{{ errors.pv2[0] }}</small>
        </div>
        <div class="field col-12 md:col-3" v-show="false">
          <span><strong>PU VENTA 3:</strong></span>
          <InputNumber
            id="pv3"
            placeholder="0.00"
            v-model="data_producto.pv3"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            :disabled="data_producto.id && !actualizar_precio_venta"
          />
          <small class="p-invalid" v-if="errors.pv3">{{ errors.pv3[0] }}</small>
        </div>
        <div class="field col-12 md:col-3" v-show="false">
          <span><strong>PU VENTA 4:</strong></span>
          <InputNumber
            id="pv4"
            placeholder="0.00"
            v-model="data_producto.pv4"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            :disabled="data_producto.id && !actualizar_precio_venta"
          />
          <small class="p-invalid" v-if="errors.pv4">{{ errors.pv4[0] }}</small>
        </div>
        <div class="field col-12 md:col-3" v-show="false">
          <span><strong>PU VENTA 5:</strong></span>
          <InputNumber
            id="pv5"
            placeholder="0.00"
            v-model="data_producto.pv5"
            showButtons
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            :disabled="data_producto.id && !actualizar_precio_venta"
          />
          <small class="p-invalid" v-if="errors.pv5">{{ errors.pv5[0] }}</small>
        </div>
        <div
          class="field col-12 md:col-5"
          v-if="data_producto.tipo_catalogo == 1"
        >
          <span><strong>CASO DE USO: </strong></span>
          <Textarea
            v-model="data_producto.caso_uso"
            :rows="1"
            placeholder="Uso del producto"
            autoResize
          />
        </div>
        <div
          :class="
            mostrar_cif ? 'field col-12 md:col-3' : 'field col-12 md:col-5'
          "
          v-if="data_producto.tipo_catalogo == 1"
        >
          <span><strong>UBICACI&Oacute;N: </strong></span>
          <Textarea
            v-model="data_producto.ubicacion"
            :rows="1"
            placeholder="Ubicación..."
            autoResize
          />
        </div>
        <div
          class="field col-12 md:col-2"
          v-if="data_producto.tipo_catalogo != 1"
        >
          <span><strong>INTERMEDIARIO: </strong> </span>
          <InputNumber
            placeholder="0.00"
            v-model="data_producto.comision_medico"
            :inputClass="'text-right'"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            :disabled="!actualizar_precio_compra"
            v-tooltip.top="'Comisión Intermediario'"
            :max="data_producto.precio_sugerido - data_producto.precio_compra"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ESTADO: </strong></span>
          <Dropdown
            v-model="data_producto.estado"
            :disabled="!data_producto.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado"
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="grid col-12">
          <div
            class="field col-12 md:col-2"
            v-show="'Producto Homologar' in auth.user.permissions"
          >
            <span
              v-if="data_producto.facturado == 1"
              class="mr-2 mt-2"
              v-tooltip.top="'Producto Homologado'"
              ><strong
                style="
                  background-color: green;
                  color: white;
                  border-radius: 5px;
                  padding: 5px;
                "
              >
                HOMOLOGADO: <i class="pi pi-check"></i></strong
            ></span>
            <span class="mr-2"><strong>HOMOLOGAR: </strong></span>
            <Checkbox
              v-model="facturar_producto"
              :binary="true"
              v-tooltip.top="
                data_producto.facturado == 1
                  ? 'Producto Homologado'
                  : 'Homologar Producto'
              "
              :disabled="data_producto.facturado == 1"
            />
          </div>
          <div
            class="field col-12 md:col-10"
            v-show="'Producto Homologar' in auth.user.permissions"
          >
            <span><strong>SELECCIONE C&Oacute;DIGO SIAT: </strong></span>
            <Dropdown
              v-model="codigoSINSelect"
              :options="codigossin"
              :filter="true"
              optionLabel="descripcionProducto"
              placeholder="Seleccione un codigo siat"
            >
              <template #option="slotProps">
                <span
                  class="font-weight-bold"
                  style="font-size: 1em"
                  v-tooltip.top="slotProps.option.descripcionProducto"
                >
                  {{
                    slotProps.option.descripcionProducto.length > 80
                      ? slotProps.option.descripcionProducto.substring(0, 80) +
                        "..."
                      : slotProps.option.descripcionProducto
                  }}
                </span>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
      />
      <Button
        :label="data_producto.id == null ? 'GUARDAR' : 'ACTUALIZAR'"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        :loading="enviado"
        @click="guardarProducto"
      />
    </template>
  </Dialog>
  <UnidadMedidaCreate
    :show="UnidadMedidaModal"
    :unidadmedida="unidadMedida"
    @closeModal="cerrarModalUnidadMedida"
    @actualizarListado="cargarUnidadesMedidas"
  ></UnidadMedidaCreate>
  <LaboratorioCreate
    :mostrar="LaboratorioModal"
    :laboratorio="laboratorio"
    @closeModal="cerrarModalLaboratorio"
    @actualizarListadoLaboratorio="cargarLaboratorios"
  ></LaboratorioCreate>
  <GrupoCreate
    :show="GrupoModal"
    :grupo="grupo"
    @closeModal="cerrarModalGrupo"
    @actualizarListado="cargarGrupos"
  >
  </GrupoCreate>
  <SubGrupoCreate
    :show="SubGrupoModal"
    :subgrupo="subgrupo"
    @closeModal="cerrarModalSubGrupo"
    @actualizarListado="cargarSubGrupos"
  >
  </SubGrupoCreate>
  <ProcedenciaCreate
    :show="ProcedenciaModal"
    :procedencia="procedencia"
    @closeModal="cerrarModalProcedencia"
    @actualizarListado="cargarProcedencias"
  >
  </ProcedenciaCreate>
</template>

<script>
import UnidadMedidaCreate from "@/module/unidadmedida/UnidadMedidaCreate.vue";
import LaboratorioCreate from "@/module/laboratorios/LaboratorioCreate.vue";
import ProductService from "@/service/ProductService";
import LaboratorioService from "@/service/LaboratorioService";
import UnidadmedidaService from "@/service/UnidadmedidaService";
import { useAuth } from "@/stores";
import SucursalService from "@/service/SucursalService";
import GrupoService from "@/service/GrupoService";
import SubGrupoService from "@/service/SubGrupoService";
import ProcedenciaService from "@/service/ProcedenciaService";
import GrupoCreate from "@/module/grupos/GrupoCreate.vue";
import SubGrupoCreate from "@/module/subgrupos/SubGrupoCreate.vue";
import ProcedenciaCreate from "@/module/procedencias/ProcedenciaCreate.vue";

export default {
  components: {
    UnidadMedidaCreate,
    LaboratorioCreate,
    GrupoCreate,
    ProcedenciaCreate,
    SubGrupoCreate,
  },
  emits: ["closeModal", "actualizarListadoProductos"],
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    producto: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  productoService: null,
  laboratorioService: null,
  unidadmedidaService: null,
  sucursalService: null,
  auth: null,
  grupoService: null,
  subgrupoService: null,
  procedenciaService: null,
  data() {
    return {
      activarModal: this.mostrar,
      data_producto: this.producto,
      laboratorios: {},
      unidadesmedidas: {},
      laboratorioSelect: {},
      unidadSelect: {},
      UnidadMedidaModal: false,
      LaboratorioModal: false,
      laboratorio: { estado: { label: "Activo", value: 1 } },
      unidadMedida: { estado: { label: "Activo", value: 1 } },
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      enviado: false,
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      stock_minimo: 0,
      stock_maximo: 0,
      precio_sugerido: 0,
      actualizar_precio_compra: 0,
      actualizar_precio_venta: 0,
      sucursalSelect: null,
      sucursales: [],
      //si data_producto.facturado == 1, entonces facturar_producto = 1
      facturar_producto: 0,
      codigoSINSelect: { label: "Seleccione un codigo siat", value: null },
      unidadMedidaSelect: {},
      codigossin: [],
      tipos_catalogo: [
        { label: "PRODUCTO", value: 1 },
        { label: "SERVICIO", value: 2 },
      ],
      grupos: {},
      grupo: { estado: { label: "Activo", value: 1 } },
      grupoSelect: {},
      procedencias: {},
      subgrupos: {},
      subgrupo: { estado: { label: "Activo", value: 1 } },
      subgrupoSelect: {},
      procedencia: { estado: { label: "Activo", value: 1 } },
      procedenciaSelect: {},
      GrupoModal: false,
      SubGrupoModal: false,
      ProcedenciaModal: false,
      parametros_sistema: {},
      mostrar_cif: false,
      stock_decimales: false,
    };
  },
  created() {
    this.auth = useAuth();
    this.productoService = new ProductService();
    this.laboratorioService = new LaboratorioService();
    this.unidadmedidaService = new UnidadmedidaService();
    this.sucursalService = new SucursalService();
    this.grupoService = new GrupoService();
    this.subgrupoService = new SubGrupoService();
    this.procedenciaService = new ProcedenciaService();
  },
  mounted() {
    /* this.cargarLaboratorios();
    this.cargarUnidadesMedidas();
    this.cargarSucursales();
    this.cargarCodigosSIN(); */
    this.getDatosNecesariosProducto();
  },
  methods: {
    activarActualizarPrecioCompra() {
      this.actualizar_precio_compra = !this.actualizar_precio_compra;
    },
    activarActualizarPrecioVenta() {
      this.actualizar_precio_venta = !this.actualizar_precio_venta;
    },
    changeTipoCatalogo() {
      if (this.data_producto.tipo_catalogo != 1) {
        this.data_producto.stock_actual = 999999999;
        this.actualizar_precio_compra = true;
      } else {
        this.data_producto.stock_actual = 0;
        this.actualizar_precio_compra = false;
      }
    },
    getDatosNecesariosProducto() {
      this.productoService.getDatosNecesariosProducto().then((response) => {
        this.laboratorios = response.datos.fabricas ?? [];
        this.unidadesmedidas = response.datos.unidadmedida ?? [];
        this.codigossin = response.datos.codigossin ?? [];
        this.sucursales = response.datos.sucursales || [];
        this.sucursalSelect = this.sucursales[0].id || null;
        this.grupos = response.datos.grupos ?? [];
        this.subgrupos = response.datos.subgrupos ?? [];
        this.procedencias = response.datos.procedencias ?? [];
        this.parametros_sistema = response.datos.parametros_sistema ?? [];
        this.parametros_sistema.some((element) => {
          if (element.nombre == "mostrar_cif") {
            this.mostrar_cif = true;
          }
          if (element.nombre == "stock_decimales") {
            this.stock_decimales = true;
          }
        });
      });
    },
    cargarGrupos(grupo = null) {
      this.grupoService
        .getGrupoAll()
        .then((response) => {
          this.grupos = response.grupos;
          if (grupo) {
            this.grupos.forEach((element) => {
              if (element.id == grupo.id) {
                this.grupoSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarSubGrupos(subgrupo = null) {
      this.subgrupoService
        .getSubGrupoAll()
        .then((response) => {
          this.subgrupos = response.subgrupos;
          if (subgrupo) {
            this.subgrupos.forEach((element) => {
              if (element.id == subgrupo.id) {
                this.subgrupoSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarProcedencias(procedencia = null) {
      this.procedenciaService
        .getProcedenciaAll()
        .then((response) => {
          this.procedencias = response.procedencias;
          if (procedencia) {
            this.procedencias.forEach((element) => {
              if (element.id == procedencia.id) {
                this.procedenciaSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activarGrupoModal() {
      this.GrupoModal = true;
      this.grupo = {
        estado: { label: "ACTIVO", value: 1 },
      };
    },
    cerrarModalGrupo() {
      this.SubGrupoModal = false;
      this.subgrupo = {};
    },
    activarSubGrupoModal() {
      this.SubGrupoModal = true;
      this.subgrupo = {
        estado: { label: "ACTIVO", value: 1 },
      };
    },
    cerrarModalSubGrupo() {
      this.GrupoModal = false;
      this.grupo = {};
    },
    activarProcedenciaModal() {
      this.ProcedenciaModal = true;
      this.procedencia = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerrarModalProcedencia() {
      this.ProcedenciaModal = false;
      this.procedencia = {};
    },
    cargarCodigosSIN() {
      this.productoService
        .getCodigoSIN()
        .then((response) => {
          this.codigossin = response.codigossin || [];
          if (response.status == 200) {
            this.$toast.add({
              severity: "success",
              summary: "Exito",
              detail: response.message,
              life: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarSucursales() {
      this.sucursalService.getSucursalesAll().then((response) => {
        this.sucursales = response;
        this.sucursalSelect = this.sucursales[0] ?? null;
      });
    },
    cerrarModalLaboratorio() {
      this.LaboratorioModal = false;
      this.laboratorio = {};
    },
    cerrarModalUnidadMedida() {
      this.UnidadMedidaModal = false;
      this.unidadMedida = {};
    },
    activarLaboratorioModal() {
      this.LaboratorioModal = true;
      this.laboratorio = {
        estado: { label: "Activo", value: 1 },
      };
    },
    activarUnidadMedidaModal() {
      this.UnidadMedidaModal = true;
      this.unidadMedida = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cargarLaboratorios(fabrica = null) {
      this.laboratorioService
        .getAllLaboratorios()
        .then((response) => {
          this.laboratorios = response.fabricas;
          if (fabrica) {
            this.laboratorios.forEach((element) => {
              if (element.id == fabrica.id) {
                this.laboratorioSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarUnidadesMedidas(unidadmedida = null) {
      this.unidadmedidaService
        .getUnidadmedidasAll()
        .then((response) => {
          this.unidadesmedidas = response;
          if (unidadmedida) {
            this.unidadesmedidas.forEach((element) => {
              if (element.id == unidadmedida.id) {
                this.unidadSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeModal() {
      this.errors = {};
      this.laboratorioSelect = {};
      this.unidadSelect = {};
      this.activarModal = false;
      this.$emit("closeModal");
      this.actualizar_precio_compra = 0;
      this.sucursalSelect = this.sucursales[0];
      this.actualizar_precio_compra = 0;
      this.actualizar_precio_venta = 0;
      this.facturar_producto = 0;
      this.codigoSINSelect = {};
      this.unidadMedidaSelect = {};
      this.enviado = false;
      this.grupoSelect = {};
      this.subgrupoSelect = {};
      this.procedenciaSelect = {};
    },
    guardarProducto() {
      if (
        this.facturar_producto == 1 &&
        this.data_producto.codigo_interno == null
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe ingresar un codigo interno para el producto",
          life: 3000,
        });
        return;
      }
      if (this.subgrupoSelect == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un subgrupo",
          life: 3000,
        });
        return;
      }
      this.errors = {};
      this.enviado = true;
      if (this.data_producto.id) {
        //Actualizar
        let tmp = {
          ...this.data_producto,
          estado: this.data_producto.estado.value,
          fabrica_id: this.laboratorioSelect.id,
          unidadmedida_id: this.unidadSelect.id,
          actualizar_precio_compra: this.actualizar_precio_compra ? 1 : 0,
          actualizar_precio_venta: this.actualizar_precio_venta ? 1 : 0,
          sucursal_id: this.sucursalSelect.id ?? null,
          stock_actual: this.data_producto.stock_actual ?? 0,
          /* facturar_producto: this.facturar_producto ? 1 : 0, */
          facturar_producto: this.data_producto.facturado
            ? 1
            : this.facturar_producto
            ? 1
            : 0,
          unidad_medida_sin: this.unidadMedidaSelect.id,
          codigo_siat:
            this.codigoSINSelect != null
              ? this.codigoSINSelect.codigoProducto
              : null,
          grupo_id: this.grupoSelect.id ?? 1,
          subgrupo_id: this.subgrupoSelect.id ?? 1,
          procedencia_id: this.procedenciaSelect.id,
          precio_cif: this.data_producto.precio_cif ?? 0,
        };

        this.productoService.actualizarProducto(tmp).then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            this.errors = response.errors;
          } else {
            this.errors = {};
            if (response.status == 400) {
              this.$toast.add({
                severity: "error",
                summary: "Error!",
                detail: response.mensaje,
                life: 3000,
              });
              return;
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Actualizado!",
                detail: response.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListadoProductos");
              this.$emit("closeModal");
            }
          }
          this.enviado = false;
        });
      } else {
        //Registrar nuevo producto
        if (this.laboratorioSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar un laboratorio",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        if (this.unidadSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar una unidad de medida",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        let tmp = {
          ...this.data_producto,
          estado: this.data_producto.estado.value,
          fabrica_id: this.laboratorioSelect.id,
          unidadmedida_id: this.unidadSelect.id,
          facturar_producto: this.facturar_producto ? 1 : 0,
          unidad_medida_sin: this.unidadMedidaSelect.id,
          codigo_siat:
            this.codigoSINSelect != null
              ? this.codigoSINSelect.codigoProducto
              : null,
          actualizar_precio_compra: this.actualizar_precio_compra ? 1 : 0,
          actualizar_precio_venta: this.actualizar_precio_venta ? 1 : 0,
          sucursal_id: this.sucursalSelect.id ?? null,
          stock_actual: this.data_producto.stock_actual ?? 0,
          grupo_id: this.grupoSelect.id ?? 1,
          subgrupo_id: this.subgrupoSelect.id ?? 1,
          procedencia_id: this.procedenciaSelect.id,
          precio_cif: this.data_producto.precio_cif ?? 0,
          codigo_interno: this.data_producto.codigo_interno ?? "",
        };
        this.productoService.registrarProducto(tmp).then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            this.errors = response.errors;
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: response.mensaje,
              life: 3000,
            });
          } else {
            this.errors = {};
            if (response.status == 400) {
              this.$toast.add({
                severity: "error",
                summary: "Error!",
                detail: response.mensaje,
                life: 3000,
              });
              return;
            } else {
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: response.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListadoProductos");
              this.$emit("closeModal");
            }
          }
          this.enviado = false;
        });
      }
    },
    findIndex(id) {
      let index = -1;
      for (let i = 0; i < this.productos.length; i++) {
        if (this.productos[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
    changeSucursal() {
      let datos = {
        producto_id: this.data_producto.id,
        sucursal_id: this.sucursalSelect.id,
      };
      this.productoService.getProductoSucursal(datos).then((response) => {
        if (response.stock.length == 0) {
          if (this.data_producto.tipo_catalogo == 1) {
            this.data_producto.stock_actual = 0;
          } else {
            this.data_producto.stock_actual = 999999999;
          }
          this.data_producto.precio_compra = 0;
          this.data_producto.precio_sugerido =
            this.data_producto.precio_sugerido ?? 0;
          this.data_producto.ubicacion = this.data_producto.ubicacion ?? "";
          this.data_producto.comision_medico = 0;
          this.$toast.add({
            severity: "info",
            summary: "Cambio de sucursal!",
            detail: "Se cargaron los datos de la sucursal seleccionada",
            life: 3000,
          });
          return;
        }
        this.data_producto.stock_actual = parseFloat(
          response.stock[0].cantidad ?? 0
        );
        this.data_producto.precio_compra = parseFloat(
          response.stock[0].precio_compra ?? 0
        );
        this.data_producto.precio_sugerido = parseFloat(
          response.stock[0].precio_venta ?? 0
        );
        this.data_producto.pv2 = parseFloat(response.stock[0].pv2 ?? 0);
        this.data_producto.pv3 = parseFloat(response.stock[0].pv3 ?? 0);
        this.data_producto.pv4 = parseFloat(response.stock[0].pv4 ?? 0);
        this.data_producto.pv5 = parseFloat(response.stock[0].pv5 ?? 0);
        this.data_producto.ubicacion = response.stock[0].ubicacion ?? "";
        this.data_producto.comision_medico = parseFloat(
          response.stock[0].comision_medico ?? 0
        );
        this.data_producto.precio_cif = parseFloat(
          response.stock[0].precio_cif ?? 0
        );
        this.$toast.add({
          severity: "info",
          summary: "Cambio de sucursal!",
          detail: "Se cargaron los datos de la sucursal seleccionada",
          life: 3000,
        });
      });
    },
  },
  watch: {
    mostrar(newVal) {
      this.activarModal = newVal;
    },
    producto(newVal) {
      this.data_producto = newVal;
      // cuando es nuevo producto que cargue el primer elemento de cada lista que tenga el id 1
      if (this.data_producto.id == null) {
        this.grupoSelect = this.grupos.find((grupo) => grupo.id == 1);
        this.subgrupoSelect = this.subgrupos.find(
          (subgrupo) => subgrupo.id == 1
        );
        this.procedenciaSelect = this.procedencias.find(
          (procedencia) => procedencia.id == 1
        );
        this.laboratorioSelect = this.laboratorios.find(
          (fabrica) => fabrica.id == 1 ?? null
        );
        this.unidadSelect = this.unidadesmedidas.find(
          (unidad) => unidad.id == 1 ?? null
        );
        this.sucursalSelect = this.sucursales.find(
          (sucursal) => sucursal.id == 1 ?? null
        );
        return;
      } else {
        this.grupoSelect = this.grupos.find(
          (grupo) => grupo.id == this.data_producto.grupo_id
        );
        this.subgrupoSelect = this.subgrupos.find(
          (subgrupo) => subgrupo.id == this.data_producto.subgrupo_id
        );
        this.procedenciaSelect = this.procedencias.find(
          (procedencia) => procedencia.id == this.data_producto.procedencia_id
        );
        this.laboratorioSelect = this.laboratorios.find(
          (fabrica) => fabrica.id == this.data_producto.fabrica_id ?? null
        );

        this.unidadSelect = this.unidadesmedidas.find(
          (unidad) => unidad.id == this.data_producto.unidadmedida_id ?? null
        );
        this.codigoSINSelect = this.codigossin.find(
          (codigo) => codigo.codigoProducto == this.data_producto.codigo_siat
        );
        this.sucursalSelect = this.sucursales.find(
          (sucursal) => sucursal.id == 1 ?? null
        );
        if (this.data_producto.tipo_catalogo != 1) {
          this.data_producto.comision_medico = parseFloat(
            this.data_producto.comision_medico ?? 0
          );
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
